.ContactPage .swiper {
    width: 100%;
    height: 100% !important;
}

.ContactPage .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ContactPage .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 600px){
.ContactPage .swiper {
    height: 100% !important;
    width: 97% !important;
}
    .ContactPage .swiper-slide img {
        width: 70%;
    }
}

